// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-blog-src-templates-post-js": () => import("/opt/build/repo/node_modules/gatsby-theme-blog/src/templates/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-src-templates-post-js" */),
  "component---node-modules-gatsby-theme-blog-src-templates-posts-js": () => import("/opt/build/repo/node_modules/gatsby-theme-blog/src/templates/posts.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-src-templates-posts-js" */),
  "component---node-modules-gatsby-theme-mdx-deck-src-templates-deck-js": () => import("/opt/build/repo/node_modules/gatsby-theme-mdx-deck/src/templates/deck.js" /* webpackChunkName: "component---node-modules-gatsby-theme-mdx-deck-src-templates-deck-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/demo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-screenshots-js": () => import("/opt/build/repo/demo/src/pages/screenshots.js" /* webpackChunkName: "component---src-pages-screenshots-js" */),
  "component---src-pages-charts-index-mdx": () => import("/opt/build/repo/demo/src/pages/charts/index.mdx" /* webpackChunkName: "component---src-pages-charts-index-mdx" */),
  "component---src-pages-maps-index-mdx": () => import("/opt/build/repo/demo/src/pages/maps/index.mdx" /* webpackChunkName: "component---src-pages-maps-index-mdx" */),
  "component---src-pages-images-index-mdx": () => import("/opt/build/repo/demo/src/pages/images/index.mdx" /* webpackChunkName: "component---src-pages-images-index-mdx" */),
  "component---src-pages-deck-index-mdx": () => import("/opt/build/repo/demo/src/pages/deck/index.mdx" /* webpackChunkName: "component---src-pages-deck-index-mdx" */)
}

