import { Head, Image, Notes, Appear, Invert, Split, SplitRight, FullScreenCode, Horizontal } from "gatsby-theme-mdx-deck";
import Counter from "../../../../decks/counter";
import big from "../../../../decks/theme";
import React from 'react';
export default {
  Head,
  Image,
  Notes,
  Appear,
  Invert,
  Split,
  SplitRight,
  FullScreenCode,
  Horizontal,
  Counter,
  big,
  React
};